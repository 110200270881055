import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import Layout from "../components/layout";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import SeoPageLogos from "../components/seo-page-logos";
import SeoWhatWeSolve from "../components/seo-what-we-solve";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const SEOSussexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			jasonImg: wpMediaItem(title: { eq: "Jasoncorneslogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "SEARCH ENGINE-OPTIMISATION-IMAGE-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seymourImg: wpMediaItem(title: { eq: "Seymourslogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			videos: allWpVideo(
				filter: { videoFields: { seoPage: { in: "Web Design Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					videoFields {
						url
						seoPage
					}
					title
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			foehImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "SEO Sussex" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "SEO Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "SEO Sussex",
				item: {
					url: `${siteUrl}/seo-sussex`,
					id: `${siteUrl}/seo-sussex`,
				},
			},
		],
	};
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/seo-sussex`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SeoHero
						page="SEO Sussex"
						title={`SEO \n<span class="text-secondary">Sussex</span>`}
						description={
							<span>
								Search engine optimisation for Sussex businesses that want to
								succeed online and achieve more from their digital marketing.
							</span>
						}
						buttons={[
							<Button
								className="px-4 py-2 bg-white border-white w-100 cta-btn w-md-auto mt-md-4 me-md-3 fs-5 blue-link-yellow"
								variant="primary"
								href="#form"
							>
								Talk to an expert
							</Button>,
							<Button
								as={Link}
								className="px-5 py-2 mt-4 border-white w-100 cta-btn w-md-auto fs-5 white-link"
								variant="primary"
								to="/seo-audit"
							>
								Audit my website
							</Button>,
						]}
					/>
				</section>

				{/* <SeoWwaSection
          spanXl={6}
          spanLg={8}
          smallHeading="SEO Agency in Sussex"
          heading="Do you want to increase your online exposure?"
          text={
            <div>
              <p>
                At RJM Digital we enjoy working closely with clients across a
                variety of industries and markets - regardless of whether they
                are a local business or an international organisation.
              </p>
              <p>
                Your website is one of the most important aspects of your online
                presence so investing in a professionally designed website is a
                must. We focus on our clients and keep their needs and
                requirements at the forefront of our minds throughout our
                journey together.
              </p>
              <p>
                Our results-driven expert team will help you to bring long term
                business growth from day 1 of your new website with our proven
                SEO solution.
              </p>
            </div>
          }
          buttonText="Talk to an expert"
          buttonUrl="#form"
          noImages
        /> */}

				<section id="intro" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6">
							<Col lg={6} className="">
								<p className="ssp-bold text-uppercase text-light-blue">
									SEO Agency in Sussex
								</p>
								<h2 className="mb-5 display-5 text-primary">
									Do you want to increase your online exposure?
								</h2>

								<p>
									At RJM Digital we enjoy working closely with customers across
									a variety of industries and markets - regardless of whether
									they are a local business or an international organisation.
								</p>
								<p>
									Your website is one of the most <strong>important</strong>{" "}
									aspects of your online presence so investing in a
									professionally designed website is always a must. We focus on
									our customers and keep their needs and requirements at the
									forefront of our minds throughout our journey together.
								</p>
								<p>
									Implementing a good SEO strategy is key to boosting the number
									of conversions, increasing your websites{" "}
									<strong>visibility</strong>, and improving your lead
									generation in the most <strong>cost-effective</strong> way.
									Applying Google's <strong>best practices</strong> to your site
									will ultimately drive the top results your business needs.
								</p>
								<p>
									Our <strong>results-driven</strong> expert team will help you
									to bring long term business growth from <strong>day 1</strong>{" "}
									of your new website with our proven SEO solution, helping you
									achieve online success.
								</p>

								<Button
									className="px-4 py-2 mt-4 w-100 cta-btn w-md-auto fs-5 white-link-yellow"
									variant="primary"
									as={Link}
									to="/seo-audit"
								>
									Request a SEO audit
								</Button>
							</Col>
							<Col className="pt-5 pt-lg-0" lg={5}>
								<ReactPlayer
									playsinline
									loop
									muted
									playing
									className="h-auto w-100"
									title="an image of how SEO can help Sussex businesses reach the number 1 spot on search engines"
									url="https://rjm-digital.rjmdigital.net/wp-content/uploads/2023/10/changing-website-stock-images-1-1.mp4"
								/>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="pb-5 pb-lg-7">
					<SeoPageLogos heading={null} page="SEO Eastbourne" />
				</section>

				<SeoWhatWeSolve
					buttonText="Audit my website"
					buttonUrl="/seo-audit"
					heading={<span>Professionals in Local SEO for Sussex companies</span>}
					image={data.foehImg.gatsbyImage}
					imageAlt={data.foehImg.altText}
					imgHeight="35rem"
					last
					text={
						<div>
							<h3 className="text-primary fs-2 ">
								Keyword <span className="text-light-blue">research</span>
							</h3>
							<p>
								For <strong>successful SEO</strong> and to get your{" "}
								<strong>desired results</strong> you must begin with finding
								your businesses relevant keywords on Google. You will then need
								to optimise these in order to rank on the{" "}
								<strong>first page</strong> of the search engine results. You
								can find the most appropriate terms quickly by typing phrases
								such as ‘local SEO’ into Google. Make a short list and allow
								them to be searchable by hand.
							</p>
							<h3 className="mt-5 text-primary fs-2">
								Strategies proven to work across any{" "}
								<span className="text-light-blue">search engine</span>
							</h3>
							<p>
								Even though Google accounts for{" "}
								<strong>over 90% of total searches</strong> performed online, we
								ensure that your websites are able to be{" "}
								<strong>found online</strong> at the <strong>top spots</strong>{" "}
								on all the other search engines too such as Bing, DuckDuckGo and
								Yandex.
							</p>
							<h3 className="mt-5 text-primary fs-2">
								Get your free SEO <span className="text-light-blue">audit</span>
							</h3>
							<p>
								In your free SEO audit, we will identify the benefits that SEO
								can bring to your business and how ranking higher on search
								engine results pages can bring you positive results such as an
								increase the amount of <strong>organic traffic</strong> you
								receive as well as the number of enquiries your business
								achieves. We will explore what is needed to help your website
								rank higher on Google and calculate your Return on Investment.
							</p>
						</div>
					}
				/>
				<section
					id="results"
					style={{
						background:
							"transparent linear-gradient(52deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="pb-5 pb-lg-7"
				>
					<Container>
						<Row className="py-5 align-items-center py-xl-0">
							<Col lg={{ span: 8, order: "last" }}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.seoImg.gatsbyImage}
										alt={data.seoImg.altText}
									/>
								</div>
							</Col>
							<Col lg={4} className=" mt-lg-0">
								<h2 className="mb-4 text-white display-5">
									SEO services that produce results
								</h2>
								<p className="text-white">
									We have worked with lots of local Sussex businesses, allowing
									them to build their online presence and receive better web
									results than ever before.
								</p>
								<p className="text-white">
									The way Google crawl and index websites is continually
									changing, so our SEO team keep up-to-date to ensure your site
									is always achieving more.
								</p>
								<Button
									className="px-4 py-2 mt-4 w-100 w-md-auto fs-5 primary-link"
									variant="white"
									as={Link}
									to="#form"
								>
									Talk to an expert
								</Button>
								<Button
									as={Link}
									className="px-4 py-2 mt-4 border-white w-100 cta-btn w-md-auto ms-md-3 ms-lg-0 ms-xl-3 fs-5 white-link"
									variant="primary"
									to="/contact-us"
								>
									Get in touch
								</Button>
							</Col>
						</Row>
						<Row className="py-5 mt-4 align-items-center our-results-seo mt-lg-0 py-lg-0 gy-5 gy-lg-0">
							<Col className="p-4 text-center" xs={12} lg={true}>
								<div className="p-4 bg-jason">
									<GatsbyImage
										className="mw-100 "
										image={data.jasonImg.gatsbyImage}
										alt={data.jasonImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="text-center ssp-bold text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">3</p>
								<h3 className="fs-5">Month ROI</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">
									200%
								</p>
								<h3 className="fs-5">Increase traffic</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">5x</p>
								<h3 className="fs-5">More enquiries</h3>
							</Col>
						</Row>
						<Row className="py-5 mt-5 align-items-center our-results-seo py-lg-0 gy-5 gy-lg-0">
							<Col xs={12} className="p-4 text-center" lg={true}>
								<div className="p-4 bg-black">
									<GatsbyImage
										className="mw-100 "
										image={data.seymourImg.gatsbyImage}
										alt={data.seymourImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="text-center ssp-bold text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">5</p>
								<h3 className="fs-5">Locations at #1</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">50%</p>
								<h3 className="fs-5">Increase in CTR</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">2x</p>
								<h3 className="fs-5">More enquiries</h3>
							</Col>
						</Row>
					</Container>
				</section>

				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection
						heading="SEO Eastbourne Reviews"
						page="Our Web Services"
					/>
				</section> */}

				{/* <section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<div className="iframe-container-new">
									<iframe
										className="responsive-iframe"
										src="https://www.youtube.com/embed/1fmeuE1eVUU"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
										loading="lazy"
									></iframe>
								</div>
							</Col>
						</Row>
					</Container>
				</section> */}
				{/* <section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<h2 className="mb-5 text-primary fs-1">
									Recent Eastbourne Web Design blog articles
								</h2>
							</Col>
						</Row>
						<Row className="g-5 gx-lg-7 h-100">
							{data.allWpPost.nodes.map((blog, index) => (
								<Col style={{ minHeight: "100%" }} lg={6}>
									<div
										className="bg-white position-relative"
										style={{
											overflow: "hidden",
											borderRadius: "20px",
											boxShadow: "0px 3px 60px #00000029",
											minHeight: "100%",
										}}
									>
										<GatsbyImage
											image={blog.blogFields.featuredImage.gatsbyImage}
											alt={blog.blogFields.featuredImage.altText}
											className="w-100 seo-blog-image"
										/>
										<div className="p-4">
											<h2 className="mb-4 fs-4 pe-lg-10 text-primary ssp-bold">
												{blog.title}
											</h2>
											<div className="w-100" style={{ height: "25px" }}></div>
											<Link
												to={`/blog/${convertToSlug(blog.blogFields.category)}/${
													blog.slug
												}`}
												className="bottom-0 pb-4 med-grey-link ssp-bold position-absolute"
											>
												READ BLOG
											</Link>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Container>
				</section> */}

				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar
								</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>
				<SeoMapSection
					heading="Discuss your SEO requirements with us"
					mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.2622758441216!2d0.27970641574303434!3d50.77071147952087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df735183be68e9%3A0x51b877a5d82a3df!2sRJM%20Digital!5e0!3m2!1sen!2suk!4v1663160300414!5m2!1sen!2suk"
				/>
				{/* <OSSection
					link1="/training"
					text1="TRAINING"
					link3="/web-design-eastbourne"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default SEOSussexPage;
